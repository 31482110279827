import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { OptimizelyFlagDecisionManagementService } from 'paysafe-wallet-feature-management-lib-fe';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { ConfigToken, EnvConfig } from './config/env.config';
import { NotFoundNotificationComponent } from './exception-handling/not-found-notification/not-found-notification.component';
import { RouterEventsComponent } from './router-events.component';
import { AuthInterceptor } from './service/interceptors/auth/auth.interceptor';
import { PlaidModule } from './service/plaid/plaid.module';

const optimizelyFactory = (config: EnvConfig): OptimizelyFlagDecisionManagementService =>
  new OptimizelyFlagDecisionManagementService(config.OPTIMIZELY_FEATURE_MANAGEMENT);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundNotificationComponent,
    RouterEventsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PlaidModule,
    HttpClientModule,
    ComponentsModule,
    LoggerModule.forRoot({
      serverLogLevel: NgxLoggerLevel.OFF,
      level: NgxLoggerLevel.DEBUG
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: OptimizelyFlagDecisionManagementService,
      useFactory: optimizelyFactory,
      deps: [ConfigToken]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
