import { HttpErrorResponse } from '@angular/common/http';

/**
 * Custom error class to encapsulate the error message and the original http error
 */
export class BackendError extends Error {

  /**
   * Create instance encapsulating the error message and the original http error
   *
   * @param message Custom error message
   * @param httpError Keep track of the original http error. The intention is not to avoid exposing its internals
   *                  and rather, if needed relevant accessors may use it to expose what's needed.
   */
  constructor(message: string, private httpError?: HttpErrorResponse) {
    super(message);
    Object.setPrototypeOf(this, BackendError.prototype);
  }
}
