import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SessionService {
  private sessionIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private returnUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  setSessionId(sessionId: string): void {
    this.sessionIdSubject.next(sessionId);
  }

  getSessionId(): Observable<string> {
    return this.sessionIdSubject.asObservable();
  }

  setReturnUrl(returnUrl: string): void {
    this.returnUrlSubject.next(returnUrl);
  }

  getReturnUrl(): Observable<string> {
    return this.returnUrlSubject.asObservable();
  }
}
