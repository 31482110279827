import { Injectable } from '@angular/core';

import { getCookie } from '../../util/utility-functions';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class SecureStorageService {
  XSRF_COOKIE_KEY = 'XSRF-TOKEN';

  constructor(private authService: AuthService) {
  }

  getXsrfToken(): string {
    const xsrfCookieValue = getCookie(this.XSRF_COOKIE_KEY);

    if (xsrfCookieValue) {
      return xsrfCookieValue;
    }

    return this.authService.getXsrfToken();
  }

  getAccessToken(): string {
    return this.authService.getAccessToken();
  }
}
