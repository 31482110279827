import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { catchError, filter, map, Observable, of } from 'rxjs';

import { FeatureFlag } from '../../config/feature-flag.enum';
import { AuthService } from '../../service/auth/auth.service';
import { FeatureManagementService } from '../../service/feature-management/feature-management.service';

@Component({
  selector: 'piv-login',
  standalone: true,
  template: ''
})
export class LoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    @Inject('Window') private window: Window,
    private featureManagement: FeatureManagementService,
    private log: NGXLogger
  ) {
  }

  ngOnInit(): void {
    this.setupWindow().pipe(filter(res => res)).subscribe(() => this.authenticate());
  }

  private setupWindow(): Observable<boolean> {
    if (this.window === this.window.top) {
      this.log.debug('Running in top window. Continuing.');
      return of(true);
    }

    return this.featureManagement.isFeatureEnabled(FeatureFlag.SWITCH_TO_TOP_WINDOW).pipe(
      map(isEnabled => {
        this.log.debug(`Switch to top window feature is ${isEnabled ? 'enabled' : 'disabled'}`);
        if (isEnabled) {
          this.log.warn('Switching to top window');
          this.window.top.location.href = this.window.location.href;
          return false;
        }

        this.log.warn('Remaining in embedded window. Continuing, but functionality might be borken.');
        return true;
      }),
      catchError(() => {
        this.log.error('Failed to check feature flag. Continuing in embedded window.');
        return of(true);
      })
    );
  }

  private authenticate(): void {
    const sid = this.route.snapshot.paramMap.get('sessionId');
    const secureCode = this.route.snapshot.queryParams['secureCodeHash'];

    this.authService.authenticate(sid, secureCode).pipe(catchError(() => of(false)))
      .subscribe(result => {
        if (result) {
          this.router.navigate(['/piv']);
        } else {
          this.router.navigate(['/piv', 'not-found']);
        }
      });
  }

}
