import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CardDetailsComponent } from './components/card-details/card-details.component';
import { LoginComponent } from './components/login/login.component';
import { ProcessingScreenComponent } from './components/processing-screen/processing-screen.component';
import {
  NotFoundNotificationComponent
} from './exception-handling/not-found-notification/not-found-notification.component';
import { authGuard } from './guard/auth.guard';
import { RouterEventsComponent } from './router-events.component';

const routes: Routes = [
  {
    path: 'piv',
    children: [
      {
        path: '',
        canActivate: [authGuard],
        component: RouterEventsComponent,
      },
      {
        path: 'sid/:sessionId',
        component: LoginComponent
      },
      {
        path: 'not-found',
        component: NotFoundNotificationComponent
      },
      {
        path: 'processing-screen',
        component: ProcessingScreenComponent
      },
      {
        path: '**',
        redirectTo: 'not-found'
      }
    ]
  },
  {
    path: 'card-details',
    component: CardDetailsComponent
  },
  {
    path: '**',
    redirectTo: 'piv/not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
