import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found-notification',
  templateUrl: './not-found-notification.component.html',
  styleUrls: ['./not-found-notification.component.scss']
})
export class NotFoundNotificationComponent {

}
