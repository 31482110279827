import { Injectable } from '@angular/core';

/*
 * TODO: review this later as it's subject of further refactoring and possibly
 * event handlers might be moved here if appears to make sense.
 */

@Injectable()
export class PlaidHandlerService {
  private handler: any;

  constructor() {}

  init(plaidHandler: any): void {
    this.handler = plaidHandler;
  }

  /**
   * Show plaid dialog
   */
  open(): void {
    this.handler.open();
  }
}
