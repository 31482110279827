import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-card-details',
  standalone: true,
  imports: [FormsModule, NgIf],
  templateUrl: './card-details.component.html',
  styleUrl: './card-details.component.scss'
})
export class CardDetailsComponent {
  cardDetailsSubmitted: boolean = false;
  emptyCardSubmitted: boolean = false;
  cardNumber: string = '';

  constructor(private log: NGXLogger) { }

  onProceed(): void {
    this.cardDetailsSubmitted = this.cardNumber && this.cardNumber.trim().length > 0;
    this.emptyCardSubmitted = !this.cardDetailsSubmitted;
    this.log.debug(`Card number is ${this.cardNumber}`);
  }
}
