import { NgModule } from '@angular/core';

import { FooterComponent } from './footer/footer.component';
import { ProcessingScreenComponent } from './processing-screen/processing-screen.component';

@NgModule({
  declarations: [
    FooterComponent,
    ProcessingScreenComponent
  ],
  exports: [
    FooterComponent,
    ProcessingScreenComponent
  ],
  imports: [
  ]
})
export class ComponentsModule { }
