<body>
    <form class="credit-card">
        <div class="form-header">
            <h4 class="title">Please enter your card details</h4>
        </div>

        <div class="form-body">
            <!-- Card Number -->
            <input type="text" class="card-number" name="cardNumber" [(ngModel)]="cardNumber" placeholder="Card Number">
            <input type="password" class="card-cvv" name="cardCvv" placeholder="CVV">
            <button type="submit" class="proceed-btn" name="btnSubmit" (click)="onProceed()">Proceed</button>
        </div>
    </form>
    <p *ngIf="cardDetailsSubmitted">
        Great job entering your card details!
        This functionality is still under development.
        Stay tuned for the next version to continue your adventure.
    </p>
    <p style="color: red" *ngIf="emptyCardSubmitted">
        Empty credit card number is not supported!
    </p>
</body>
