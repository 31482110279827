import { Injectable } from '@angular/core';
import { OptimizelyFlagDecisionManagementService } from 'paysafe-wallet-feature-management-lib-fe';
import { from,Observable } from 'rxjs';

import { ALL_CUSTOMERS_FEATURE } from '../../config/constants';
import { FeatureFlag } from '../../config/feature-flag.enum';

@Injectable({
  providedIn: 'root'
})
export class FeatureManagementService {
  constructor(
    private readonly optimizelyFeatureManagement: OptimizelyFlagDecisionManagementService
  ) {
  }

  /**
   * Returns a boolean observable that provides information about whether a specific feature flag
   * is enabled or not.
   * Observable is auto completed and there is no need to unsubscribe
   *
   * @param featureFlag - feature flag key in Optimizely
   */
    isFeatureEnabled(featureFlag: FeatureFlag): Observable<boolean> {
      return from(this.optimizelyFeatureManagement.isFeatureEnabled(featureFlag, ALL_CUSTOMERS_FEATURE));
    }
}
