import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { Config, ConfigToken } from '../../config/env.config';
import { AuthService } from '../auth/auth.service';
import { SecureStorageService } from '../storage/secure-storage.service';
import { StorageService } from '../storage/storage.service';
import { PlaidDialogService } from './dialog/plaid-dialog.service';
import { PlaidHandlerService } from './handler/plaid-handler.service';
import { PlaidService } from './plaid.service';
import { SessionService } from './session/plaid-session.service';

@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: 'Window', useValue: window },
    { provide: ConfigToken, useValue: Config },
    AuthService,
    PlaidHandlerService,
    PlaidDialogService,
    PlaidService,
    SessionService,
    StorageService,
    SecureStorageService
  ]
})
export class PlaidModule { }
