import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SecureStorageService } from '../../storage/secure-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  XSRF_HEADER_NAME = 'X-XSRF-TOKEN';

  constructor(
    private secureStorageService: SecureStorageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const xsrfToken = this.secureStorageService.getXsrfToken();
    const accessToken = this.secureStorageService.getAccessToken();

    const headers: any = {};
    if (xsrfToken) {
      headers[this.XSRF_HEADER_NAME] = xsrfToken;
    }

    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    const authReq = req.clone({
      setHeaders: headers
    });

    return next.handle(authReq);
  }
}
