import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, defaultIfEmpty, filter, map, Observable, of, tap } from 'rxjs';

import { LINK_TOKEN_KEY, PLAID_CONTEXT_TYPE } from '../../../config/constants';
import { Config } from '../../../config/env.config';
import { PlaidAccountVerificationStatus } from '../../../models/plaid/instrument-verification/plaid-account-verification-status';
import {
  InstrumentVerificationStatus
} from '../../../models/plaid/instrument-verification/plaid-instrument-verification-failed-status-reason';
import { PlaidParameters } from '../../../models/plaid/plaid-parameters';
import { PlaidVerificationContext } from '../../../models/plaid/plaid-verification-context';
import { StorageService } from '../../storage/storage.service';
import { PlaidHandlerService } from '../handler/plaid-handler.service';
import { PlaidService } from '../plaid.service';
import { SessionService } from '../session/plaid-session.service';

@Injectable()
export class PlaidDialogService {

  constructor(
    @Inject('Window') private window: Window,
    private plaidService: PlaidService,
    private plaidHandler: PlaidHandlerService,
    private sessionService: SessionService,
    private zone: NgZone,
    private router: Router,
    private storageService: StorageService,
  ) {
  }

  initPlaid(info: PlaidParameters): Observable<boolean> {
    return this.getPlaidLinkToken(info.token, info.usedSavedLinkToken)
      .pipe(
        map(ctx => ctx?.link_token),
        filter(linkToken => !!linkToken),
        tap(linkToken => this.storePlaidDataInSessionStorage(linkToken)),
        tap(linkToken => this.initializePlaidLinkToken(linkToken)),
        map(() => true),
        catchError(() => of(false)),
        defaultIfEmpty(false)
      )
      .pipe(
        tap(isInitSuccesfull => {
          if (!isInitSuccesfull) {
            this.redirectToMerchant();
          }
        })
      );
  }

  private getPlaidLinkToken(token?: string, useSavedTokenIfExist?: boolean): Observable<PlaidVerificationContext> {
    const linkToken: string = this.storageService.getItem(LINK_TOKEN_KEY);

    if (linkToken && useSavedTokenIfExist) {
      return of({ link_token: linkToken });
    }

    if (token) {
      return of({ link_token: token });
    }

    return this.plaidService.getPlaidVerificationContext();
  }

  private initializePlaidLinkToken(token: string): void {
    const config = {
      token,
      env: Config.PLAID_ENVIRONMENT,
      onSuccess: this.success.bind(this),
      onExit: this.exit.bind(this),
      onEvent: this.event.bind(this),
      receivedRedirectUri: ''
    };

    if (this.window.location.href.includes('?oauth_state_id=')) {
      config.receivedRedirectUri = this.window.location.href;
    }
    this.plaidHandler.init((this.window as any).Plaid.create(config));
  }

  /**
   * Event handler for successful finish of plaid dialog
   */
  private success(publicToken: string, metadata: any): void {
    this.storePublicToken(publicToken, metadata);
    const accountVerificationStatus = metadata.accounts[0].verification_status;
    if (accountVerificationStatus === PlaidAccountVerificationStatus.PENDING_MANUAL_VERIFICATION
      || accountVerificationStatus === PlaidAccountVerificationStatus.PENDING_AUTOMATIC_VERIFICATION) {
      this.redirectToMerchant();
    } else {
      this.goToProcessingScreen();
    }
  }

  private redirectToMerchant(): void {
    this.plaidService.getInstrumentVerificationDetails().subscribe(
      response => {
        this.storageService.clear();
        this.window.location.href = response.returnUrl;
      }
    );
  }

  private goToProcessingScreen(): void {
    this.router.navigate(['piv', 'processing-screen']);
  }

  /**
   * Event handler for exiting plaid dialog
   * @param err Error provided by Plaid
   * @param metadata Metadata provided by Plaid (contains user account  and his selection on plaid screens)
   */
  private exit(err: any, metadata: any): void {
    const request = this.buildFailRequest(err, metadata);
    this.processErrorDuringPlaidWindowInteraction(request);

    this.zone.run(() => {
      this.removePlaidDataInSessionStorage();
    });
  }

  /**
   * Event handler for plaid dialog events
   * @param eventName name of event
   * @param metadata meta data of event
   */
  private event(): void {
    // private event(eventName: string, metadata: any): void
    // this.zone.run(() => {
    //   this.storeEvent(eventName, metadata);
    // });
  }

  /**
   * Show plaid dialog
   */
  show(): void {
    this.plaidHandler.open();
  }

  private storePlaidDataInSessionStorage(linkToken: string): void {
    this.storageService.setItem(LINK_TOKEN_KEY, linkToken);
  }

  private removePlaidDataInSessionStorage(): void {
    this.storageService.removeItem(LINK_TOKEN_KEY);
  }

  /**
   * Store given public token and execute after public token store action
   * @param publicToken Public token
   * @param metadata Metadata
   */
  storePublicToken(publicToken: string, metadata: any): void {
    const request = this.getPublicTokenStoreRequest(publicToken, metadata);

    this.plaidService.updatePlaidVerificationContext(request).subscribe();
  }

  /**
   * Create request data for storing public token request
   * @param publicToken public token from plaid
   * @param metadata metadata about instrument
   */
  private getPublicTokenStoreRequest(publicToken: string, metadata: any): PlaidVerificationContext {
    const request: PlaidVerificationContext = {
      contextType: PLAID_CONTEXT_TYPE,
      metadata,
      public_token: publicToken
    };
    return request;
  }

  private processErrorDuringPlaidWindowInteraction(request: PlaidVerificationContext): void {
    this.plaidService.updatePlaidVerificationContext(request).subscribe();
    this.redirectToMerchant();
  }

  private buildFailRequest(err: any, metadata: any): PlaidVerificationContext {
    const request: PlaidVerificationContext = {
      contextType: PLAID_CONTEXT_TYPE,
      metadata
    };
    if (err) {
      request.error_type = InstrumentVerificationStatus.PLAID_WINDOW_INTERACTION_ERROR;
      request.error_message = 'Failed during customer interaction with plaid window';
    } else {
      request.error_type = InstrumentVerificationStatus.USER_ABANDONED_PLAID_PROCESS;
      request.error_message = 'User cancelled interaction with plaid window';
    }
    return request;
  }

}
