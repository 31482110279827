export enum InstrumentVerificationStatus {
  NOT_FOUND_CUSTOMER = 'NOT_FOUND_CUSTOMER',
  NOT_SUPPORTED_COUNTRY = 'NOT_SUPPORTED_COUNTRY',
  BANK_ACCOUNTS_MAX_NUMBER_EXCEEDED = 'BANK_ACCOUNTS_MAX_NUMBER_EXCEEDED',
  CANNOT_ADD_INSTRUMENT = 'CANNOT_ADD_INSTRUMENT',
  INSTRUMENT_VERIFICATION_FAILED = 'INSTRUMENT_VERIFICATION_FAILED',
  NOT_MATCHING_INSTRUMENT_COUNTRY = 'NOT_MATCHING_INSTRUMENT_COUNTRY',
  INVALID_BANKCODE = 'INVALID_BANKCODE',
  ADDRESS_NOT_VERIFIED = 'ADDRESS_NOT_VERIFIED',
  FAILED_ADDRESS_VERIFICATION = 'FAILED_ADDRESS_VERIFICATION',
  EXISTING_INSTRUMENT_NAME_MISMATCH = 'EXISTING_INSTRUMENT_NAME_MISMATCH',
  INSTRUMENT_NOT_UNIQUE = 'INSTRUMENT_NOT_UNIQUE',
  UNSUPPORTED_BANK = 'UNSUPPORTED_BANK',
  NAME_MISMATCH = 'NAME_MISMATCH',
  MISSING_DETAILS = 'MISSING_DETAILS',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  PLAID_WINDOW_INTERACTION_ERROR = 'PLAID_WINDOW_INTERACTION_ERROR',
  USER_ABANDONED_PLAID_PROCESS = 'USER_ABANDONED_PLAID_PROCESS',
  OTHER = 'OTHER'
}
