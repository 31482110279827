
export function getCookie(name: string): string {
  const cookies: Array<string> = document.cookie.split(';');
  const cookieName = `${name}=`;
  let c: string;

  for (let i = 0; i < cookies.length; i += 1) {
    c = cookies[i].replace(/^\s+/g, '');
    if (c.indexOf(cookieName) === 0) {
      return c.substring(cookieName.length, c.length);
    }
  }
  return '';
}
